<script setup lang="ts">
import {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuContent,
	DropdownMenuCheckboxItem,
	FormField,
	FormItem,
	FormControl,
} from '@laam/ui/base';
import countries from '../../utils/countries.json';
import LazyImage from '../LazyImage.vue';
import { useVirtualList } from '@vueuse/core';

interface Props {
	parentField: string;
	disabled: boolean;
}

withDefaults(defineProps<Props>(), {
	parentField: 'contactInformation',
});

const countriesArray = Object.entries(countries).map(([key, value]) => ({
	code: key,
	...value,
}));

const { list, containerProps, wrapperProps } = useVirtualList(countriesArray, {
	itemHeight: 35,
});
const emits = defineEmits(['update']);
</script>
<template>
	<FormField
		v-slot="{ handleChange, componentField }"
		:name="
			parentField !== 'contactInformation'
				? `${parentField}.countryCode`
				: 'countryCode'
		"
		class="w-full"
	>
		<FormItem v-auto-animate class="w-full">
			<FormControl>
				<DropdownMenu class="bg-white w-full">
					<DropdownMenuTrigger :disabled="disabled">
						<div
							class="flex items-center p-lg gap-md rounded-medium border border-gray-300 justify-between border-r-transparent rounded-r-none"
						>
							<div class="flex items-center gap-md text-md text-gray-900">
								{{ componentField.modelValue }}
							</div>
							<!-- <PhCaretDown size="16" /> -->
						</div>
					</DropdownMenuTrigger>
					<DropdownMenuContent class="w-full bg-white">
						<div
							id="listContainer"
							class="h-[400px]"
							v-bind="containerProps"
							@input.prevent
						>
							<div v-bind="wrapperProps">
								<div
									v-for="(item, index) in list"
									:key="index"
									:value="item.data.code"
								>
									<DropdownMenuCheckboxItem
										:checked="componentField.modelValue === item.data.code"
										@click="
											handleChange(item.data.code);
											emits('update', item.data.code);
										"
									>
										<template #body>
											<div class="w-full">
												<div class="flex flex-center gap-md flex-1">
													<LazyImage
														:src="item.data.flag"
														alt="country flag"
														size="20"
														class="w-5xl h-5xl !max-w-[20px]"
													/>
													<p class="text-md text-gray-900">
														+{{ item.data.phone[0] }}
													</p>
												</div>
											</div>
										</template>
									</DropdownMenuCheckboxItem>
								</div>
							</div>
						</div>
					</DropdownMenuContent>
				</DropdownMenu>
			</FormControl>
		</FormItem>
	</FormField>
</template>
